<template>
  <div class="h-100 d-flex">
    <b-container class="bg-white flex-fill p-0">
      <div class="p-2">
        <router-link
          :to="{ name: 'account' }"
          class="btn btn-outline-primary btn-sm"
        >
          <font-awesome-icon icon="arrow-left" fixed-width />
        </router-link>
      </div>
      <b-list-group>
        <b-list-group-item
          class="border-bottom m-0 rounded-0"
          tag="a"
          :to="{ name: 'profile' }"
        >
          <font-awesome-icon icon="user" fixed-width />Mes informations
          personnelles
        </b-list-group-item>
        <b-list-group-item
          class="border-bottom m-0 rounded-0"
          tag="a"
          :to="{ name: 'email' }"
        >
          <font-awesome-icon icon="envelope" fixed-width />Mon email
        </b-list-group-item>
        <b-list-group-item
          class="border-bottom m-0 rounded-0"
          tag="a"
          :to="{ name: 'phone' }"
        >
          <font-awesome-icon icon="phone" fixed-width />Mon numéro de téléphone
        </b-list-group-item>
        <b-list-group-item
          class="border-bottom m-0 rounded-0"
          tag="a"
          :to="{ name: 'iban' }"
        >
          <font-awesome-icon icon="money-check" fixed-width />Mon iban
        </b-list-group-item>
        <b-list-group-item
          class="border-bottom m-0 rounded-0"
          tag="a"
          @click="logout"
        >
          <font-awesome-icon icon="sign-out-alt" fixed-width />Déconnexion
        </b-list-group-item>
      </b-list-group>
      <transition name="swipe-left">
        <over-router-view v-if="isChild()" style="z-index: 5" />
      </transition>
    </b-container>
  </div>
</template>
<script>
import OverRouterView from "@/components/OverRouterView"
export default {
  components: {
    OverRouterView
  },
  methods: {
    logout() {
      this.$login.logout().then(response => {
        this.$router.push({ name: this.$route.meta.parent })
      })
    },
    isChild() {
      let children = ["email", "profile", "phone", "iban"]
      return children.includes(this.$route.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.list-group-item {
  border: 0;
}
</style>
